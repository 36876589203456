


































/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState, mapMutations } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import InfoToast from '@part/elements/InfoToast.vue';
import lang from '@lang/imprint.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        InfoToast
    },
    props: [],
    data() {
        return {
            imprint: '',
            filledIn: false,
            required: {
                imprint: false
            },
            defaults: {
                sock: ' ',
                scarf: ' '
            },
            accepted: ` !(),-.:;<=>#@*0123456789&?ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`
        };
    },
    computed: {
        ...mapState(['sessionData']),
        lang(){
            return lang[this.activeLang()];
        },
        filled() {
            let filledIn = true
            for (let key in this.required) {
                !this.required[key] && (filledIn = false);
            }
            return filledIn;
        },
        maxChars() {
            return this.sessionData.product_type == 'sock' ? 11 : 42;
        }
    },
    methods: {
        ...mapMutations(['setSessionData']),
        setEmpty(key, value) {
            // if not filled in, use defaults as imprint
            const type = this.sessionData['product_type'];
            this.setDataAndRedirect(key, this.pad(this.defaults[type]), '/ready');
        },
        checkAcceptedChar() {
            let currChar = this.imprint[this.imprint.length - 1];
            if (currChar !== undefined) {
                let indOfChar = this.accepted.indexOf(currChar);
                if(indOfChar < 0) {
                    this.imprint = this.imprint.substring(0, this.imprint.length - 1);
                }
            }
        },
        checkRequired(key) {
            this.checkAcceptedChar()

            if (this.imprint) {
                this.required[key] = true;
            } else {
                this.required[key] = false;
            }
        },
        pad(str) {
            // only the scarf imprint needs to be padded
            if (this.sessionData['product_type'] == 'scarf') {
                return str.padEnd(42, ' ');
            }

            return str;
        }
    },
    mounted() {
        this.imprint = this.sessionData.imprint;
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Imprint extends Vue {}
