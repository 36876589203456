export default {
    en: {
        title: 'TEXT',
        intro: {
            sock: 'Enter the text that will appear on the outside of your socks. Like the name of a friend. Or the name of the designer (yes, that’s you).',
            scarf: 'Enter text that will appear on your scarf. Like the name of the designer (yes, that’s you). You have 42 characters to go all out.'
        },
        info: 'What does it look like?',
        button: 'CONTINUE',
        skip: 'Skip',
        placeholder: 'name / word'
    },
    nl: {
        title: 'TEKST',
        intro: {
            sock: 'Vul hier de tekst in die op de bovenrand van je sokken komt te staan [A-Z, 0-9 en .,?><=;:] Bijvoorbeeld de naam van een vriend of een vriendin. Of de naam van de designer (jij dus). Er is ruimte voor 10 tekens (inclusief spaties)',
            scarf: 'Vul hier een tekst in die op je sjaal komt te staan [A-Z, 0-9 en .,?><=;:] Bijvoorbeeld de naam van de designer (jij dus). Je hebt 42 tekens om helemaal los te gaan.'
        },
        info: 'Hoe ziet het eruit?',
        button: 'DOORGAAN',
        skip: 'Overslaan',
        placeholder: 'naam / word'
    }
};
